"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotsWithBids = void 0;
var react_1 = __importStar(require("react"));
var lots_with_bids_1 = require("../api/calls/lot/lots_with_bids");
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../reducers/slices/main");
var socket_1 = require("../socket");
var lot_details_1 = require("../screens/pages/live/modals/lot_details");
var loader_content_1 = require("./loader_content");
var login_1 = require("../screens/pages/login/login");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var get_for_auction_1 = require("../api/calls/favorite/get_for_auction");
var add_1 = require("../api/calls/favorite/add");
var remove_1 = require("../api/calls/favorite/remove");
var toast_1 = require("../helpers/toast");
var bid_1 = require("../reducers/slices/bid");
var place_bid_1 = require("../screens/pages/live/automated/place_bid");
var you_tag_1 = require("./tags/you_tag");
var auction_rules_conditions_1 = require("../screens/pages/sign_up/auction_rules_conditions");
var LotsWithBids = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var currentLot = (0, react_redux_1.useSelector)(function (state) { return state.lotState; }).currentLot;
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.bidderState; }), isBidderVerified = _a.isBidderVerified, isBidderRegistered = _a.isBidderRegistered;
    var highestBids = (0, react_redux_1.useSelector)(function (state) { return state.bidState; }).highestBids;
    var _b = (0, react_1.useState)([]), lotsWithBids = _b[0], setLotsWithBids = _b[1];
    var _c = (0, react_1.useState)([]), favorites = _c[0], setFavorites = _c[1];
    var _d = (0, react_1.useState)(false), showWithBids = _d[0], setShowWithBids = _d[1];
    var _e = (0, react_1.useState)(false), showOnlyMyBids = _e[0], setShowOnlyMyBids = _e[1];
    var _f = (0, react_1.useState)(false), showOnlyMyFavorites = _f[0], setShowOnlyMyFavorites = _f[1];
    var _g = (0, react_1.useState)(currentLot), activeLot = _g[0], setActiveLot = _g[1];
    (0, react_1.useEffect)(function () {
        if (currentLot) {
            setActiveLot(currentLot);
        }
    }, [currentLot]);
    (0, react_1.useEffect)(function () {
        if (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id) {
            // console.log("Joining all lots pipes");
            // socket.emit("joinAllLotsPipes", liveAuction.entity_id);
            socket_1.socket.off("lotStatusForAuction");
            socket_1.socket.on("lotStatusForAuction", function (data) {
                var lot_entity_id = data.lot_entity_id, auction_entity_id = data.auction_entity_id, title = data.title, status = data.status, type = data.type, highest_bid = data.highest_bid;
                setLotsWithBids(function (prevLots) {
                    var foundLot = prevLots.find(function (x) { return x.entity_id === lot_entity_id; });
                    if (foundLot) {
                        foundLot.status = status;
                    }
                    determineWonLost(data);
                    return __spreadArray([], prevLots, true);
                });
            });
            (0, lots_with_bids_1.lots_with_bids)({ auction_entity_id: liveAuction.entity_id }).then(function (res) {
                if (res.success) {
                    console.log("Lots with bids:", res.content);
                    setLotsWithBids(res.content);
                    var hb = {};
                    for (var _i = 0, _a = res.content; _i < _a.length; _i++) {
                        var lot = _a[_i];
                        if (lot.bids.bids.length > 0) {
                            hb[lot.entity_id] = lot.bids.bids[0];
                        }
                    }
                    dispatch((0, bid_1.setHighestBids)(hb));
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Oops!",
                        messages: ["Something went wrong while fetching lots with bids.", res.message],
                    }));
                }
            });
            if (user === null || user === void 0 ? void 0 : user.entity_id) {
                (0, get_for_auction_1.get_favorites_for_auction)({ auction_entity_id: liveAuction.entity_id, user_entity_id: user === null || user === void 0 ? void 0 : user.entity_id }).then(function (res) {
                    if (res.success) {
                        setFavorites(res.content);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Oops!",
                            messages: ["Something went wrong while fetching favorites for auction.", res.message],
                        }));
                    }
                });
            }
        }
    }, [liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id, user === null || user === void 0 ? void 0 : user.entity_id]);
    (0, react_1.useEffect)(function () { }, [user === null || user === void 0 ? void 0 : user.entity_id]);
    (0, react_1.useEffect)(function () {
        if (liveAuction === null || liveAuction === void 0 ? void 0 : liveAuction.entity_id) {
            socket_1.socket.off("newBid");
            socket_1.socket.on("newBid", function (data) {
                var _a;
                var bid = data.bid, count = data.count;
                // push the bid to the lot's bids array's top.
                for (var _i = 0, lotsWithBids_1 = lotsWithBids; _i < lotsWithBids_1.length; _i++) {
                    var lwb = lotsWithBids_1[_i];
                    if (lwb.entity_id === bid.lot.entity_id) {
                        dispatch((0, bid_1.setHighestBids)(__assign(__assign({}, highestBids), (_a = {}, _a[bid.lot.entity_id] = bid, _a))));
                        lwb.bids.bids.unshift(bid);
                        // remove the last bid if the length is more than 9.
                        if (lwb.bids.bids.length > 9) {
                            lwb.bids.bids.pop();
                        }
                        break;
                    }
                }
                setLotsWithBids(function (prev) { return __spreadArray([], prev, true); });
            });
            socket_1.socket.off("rejectBid");
            socket_1.socket.on("rejectBid", function (data) {
                var bid_entity_id = data.bid_entity_id, lot_entity_id = data.lot_entity_id;
                for (var _i = 0, lotsWithBids_2 = lotsWithBids; _i < lotsWithBids_2.length; _i++) {
                    var lwb = lotsWithBids_2[_i];
                    if (lwb.entity_id === lot_entity_id) {
                        lwb.bids.bids = lwb.bids.bids.filter(function (x) { return x.entity_id !== bid_entity_id; });
                        break;
                    }
                }
                setLotsWithBids(function (prev) { return __spreadArray([], prev, true); });
            });
            socket_1.socket.off("backUpBids");
            socket_1.socket.on("backUpBids", function (data) {
                var bid_entity_ids = data.bid_entity_ids, lot_entity_id = data.lot_entity_id;
                for (var _i = 0, lotsWithBids_3 = lotsWithBids; _i < lotsWithBids_3.length; _i++) {
                    var lwb = lotsWithBids_3[_i];
                    if (lwb.entity_id === lot_entity_id) {
                        lwb.bids.bids = lwb.bids.bids.filter(function (x) { return !bid_entity_ids.includes(x.entity_id); });
                        break;
                    }
                }
                setLotsWithBids(function (prev) { return __spreadArray([], prev, true); });
            });
        }
    }, [lotsWithBids]);
    var determineWonLost = function (data) {
        var lot_entity_id = data.lot_entity_id, auction_entity_id = data.auction_entity_id, title = data.title, status = data.status, type = data.type, highest_bid = data.highest_bid;
        console.log("determineWonLost", data);
        if (status == "Sold") {
            if ((highest_bid === null || highest_bid === void 0 ? void 0 : highest_bid.user_entity_id) == (user === null || user === void 0 ? void 0 : user.entity_id)) {
                (0, toast_1.ourToast)({
                    content: "You won lot ".concat(title, "!"),
                });
            }
            else {
                // TODO: don't show this toast if the user never palced a bid on this lot.
                (0, toast_1.ourToast)({
                    content: "You lost lot ".concat(title, "!"),
                });
            }
        }
        else if (status == "Passed") {
            (0, toast_1.ourToast)({
                content: "The lot was passed.",
            });
        }
    };
    var filteredLots = lotsWithBids === null || lotsWithBids === void 0 ? void 0 : lotsWithBids.filter(function (x) {
        // show only lots with bids.
        if (showWithBids) {
            return x.bids.bids.length > 0;
        }
        // show only lots with my bids.
        if (showOnlyMyBids) {
            return x.bids.bids.some(function (b) { var _a; return ((_a = b.user) === null || _a === void 0 ? void 0 : _a.entity_id) === (user === null || user === void 0 ? void 0 : user.entity_id); });
        }
        // show only my favorites.
        if (showOnlyMyFavorites && favorites) {
            return favorites.some(function (f) { return f.entity_id === x.entity_id; });
        }
        return true;
    });
    return (react_1.default.createElement(loader_content_1.LoaderContent, { loading: (lotsWithBids === null || lotsWithBids === void 0 ? void 0 : lotsWithBids.length) == 0 },
        react_1.default.createElement("div", { className: "flex gap-4 pb-4" },
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Show lots that have been bid on?", value: showWithBids, onChange: function (v) {
                    setShowWithBids(v);
                    if (v) {
                        setShowOnlyMyBids(false);
                        setShowOnlyMyFavorites(false);
                    }
                } }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Show lots with my bids?", value: showOnlyMyBids, onChange: function (v) {
                    setShowOnlyMyBids(v);
                    if (v) {
                        setShowWithBids(false);
                        setShowOnlyMyFavorites(false);
                    }
                } }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Show only my favourites?", value: showOnlyMyFavorites, onChange: function (v) {
                    setShowOnlyMyFavorites(v);
                    if (v) {
                        setShowOnlyMyBids(false);
                        setShowWithBids(false);
                    }
                } })),
        react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" }, filteredLots && filteredLots.length > 0 ? (filteredLots.map(function (lot) {
            var _a;
            var highestBid = lot.bids.bids[0];
            return (react_1.default.createElement("div", { key: lot.entity_id, className: "bg-gray-200 rounded-lg flex flex-col overflow-clip shadow-lg\n              ".concat((activeLot === null || activeLot === void 0 ? void 0 : activeLot.entity_id) == lot.entity_id ? "border-4 border-green-600 animate-scale" : "border-none") },
                react_1.default.createElement("div", { className: "flex flex-row gap-1 p-1" },
                    react_1.default.createElement("div", { className: "w-20 h-20 rounded-lg overflow-clip group relative flex justify-center items-center cursor-pointer", onClick: function () {
                            dispatch((0, main_1.setContentModal)({
                                title: "Viewing Lot Details",
                                content: react_1.default.createElement(lot_details_1.LotDetailsModal, { lot_id: lot.entity_id }),
                            }));
                        } },
                        react_1.default.createElement("div", { className: "absolute opacity-0 group-hover:opacity-100 text-xs bg-white rounded-md p-1 transition-all hover:underline" }, "View"),
                        lot.images && react_1.default.createElement("img", { src: lot.images[0].uploaded_file_url, alt: "No Image", className: "w-full h-full object-cover" })),
                    react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                        react_1.default.createElement("div", { className: "flex-1 flex gap-1 items-start" },
                            react_1.default.createElement("div", { className: "text-md flex-none" },
                                "# ",
                                lot.lot_number),
                            react_1.default.createElement("div", { className: "text-md" }, "-"),
                            react_1.default.createElement("div", { className: "text-md font-bold line-clamp-2" }, lot.title)),
                        react_1.default.createElement("div", { className: "flex justify-between items-end" },
                            react_1.default.createElement("div", { className: "\n                        rounded-md p-1 text-black text-xs\n                        ".concat((function () {
                                    switch (lot.status) {
                                        case "Bidding Open":
                                            return "bg-yellow-500";
                                        case "Sold":
                                            return "bg-green-500";
                                        case "Passed":
                                            return "bg-blue-500";
                                        default:
                                            return "bg-gray-500";
                                    }
                                })()) }, lot.status),
                            react_1.default.createElement("div", { className: "flex gap-2 items-center" },
                                (function () {
                                    if (user === null || user === void 0 ? void 0 : user.entity_id) {
                                        var favorite_1 = favorites.find(function (x) { return x.entity_id === lot.entity_id; });
                                        return (react_1.default.createElement("div", { className: "hover:scale-125 cursor-pointer transition-all hover:text-red-600 group w-6 h-6\n                                flex justify-center items-center text-lg", onClick: function () {
                                                // if the lot is already in favorites, remove it.
                                                if (favorite_1) {
                                                    (0, remove_1.remove_favorite)({
                                                        lot_entity_id: lot.entity_id,
                                                        user_entity_id: user.entity_id,
                                                    }).then(function (res) {
                                                        if (res.success) {
                                                            var removed_lot_entity_id_1 = res.content;
                                                            setFavorites(function (prev) { return prev.filter(function (x) { return x.entity_id !== removed_lot_entity_id_1; }); });
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Oops!",
                                                                messages: ["Something went wrong while removing lot from favorites.", res.message],
                                                            }));
                                                        }
                                                    });
                                                }
                                                else {
                                                    (0, add_1.add_favorite)({
                                                        auction_entity_id: liveAuction.entity_id,
                                                        lot_entity_id: lot.entity_id,
                                                        user_entity_id: user.entity_id,
                                                    }).then(function (res) {
                                                        if (res.success) {
                                                            var addedLot_1 = res.content;
                                                            setFavorites(function (prev) { return __spreadArray(__spreadArray([], prev, true), [addedLot_1], false); });
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Oops!",
                                                                messages: ["Something went wrong while adding lot to favorites.", res.message],
                                                            }));
                                                        }
                                                    });
                                                }
                                            } }, (function () {
                                            return favorite_1 ? (react_1.default.createElement("div", { title: "Remove from favourites" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "text-red-600 group-hover:hidden", icon: pro_solid_svg_icons_1.faHeart }),
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "hidden group-hover:block", icon: pro_light_svg_icons_1.faHeart }))) : (react_1.default.createElement("div", { title: "Add to favourites" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "group-hover:hidden", icon: pro_light_svg_icons_1.faHeart }),
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "hidden group-hover:block", icon: pro_solid_svg_icons_1.faHeart })));
                                        })()));
                                    }
                                })(),
                                react_1.default.createElement("div", { className: "pr-0" },
                                    react_1.default.createElement("div", { className: "text-white text-xs bg-blue-500 px-2 py-1 rounded-lg cursor-pointer hover:bg-blue-400", onClick: function () {
                                            dispatch((0, main_1.setContentModal)({
                                                title: "Viewing Lot Details",
                                                content: react_1.default.createElement(lot_details_1.LotDetailsModal, { lot_id: lot.entity_id }),
                                            }));
                                        } }, "View Details")))))),
                react_1.default.createElement("div", { className: "h-1 bg-black shadow-sm" }),
                react_1.default.createElement("div", { className: "flex justify-between items-center p-1" },
                    react_1.default.createElement("div", { className: "flex gap-2 items-center" },
                        react_1.default.createElement("span", { className: "text-sm" }, "Highest bid:"),
                        react_1.default.createElement("b", { className: "text-sm" }, lot.bids.bids && lot.bids.bids.length > 0
                            ? doshx_controls_web_1.Utilities.formatMoney({
                                value: highestBid.amount,
                                decimalPlaces: 2,
                            })
                            : "R0.00"),
                        ((_a = highestBid === null || highestBid === void 0 ? void 0 : highestBid.user) === null || _a === void 0 ? void 0 : _a.entity_id) == (user === null || user === void 0 ? void 0 : user.entity_id) && react_1.default.createElement(you_tag_1.YouTag, null)),
                    lot.status == "Bidding Open" && (react_1.default.createElement("div", { className: "py-1 px-2 bg-green-600 text-sm rounded-lg text-white\n                      cursor-pointer hover:bg-green-500 transition-all duration-200", onClick: function () {
                            if (user === null || user === void 0 ? void 0 : user.entity_id) {
                                if (isBidderRegistered) {
                                    if (isBidderVerified) {
                                        dispatch((0, main_1.setContentModal)({
                                            title: "Place Bid - ".concat(lot.title),
                                            content: react_1.default.createElement(place_bid_1.PlaceBidModal, { lot_id: lot.entity_id }),
                                        }));
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Not verified yet!",
                                            messages: ["You need to be verified before you can place a bid."],
                                        }));
                                    }
                                }
                                else {
                                    dispatch((0, main_1.setDrawer)({
                                        open: true,
                                        content: (react_1.default.createElement("div", { className: "w-96" },
                                            react_1.default.createElement(auction_rules_conditions_1.AuctionRulesConditionsPage, null))),
                                    }));
                                }
                            }
                            else {
                                dispatch((0, main_1.setDrawer)({
                                    open: true,
                                    content: (react_1.default.createElement("div", { className: "w-96" },
                                        react_1.default.createElement(login_1.LogInComponent, null))),
                                }));
                            }
                        } }, "PLACE BID"))),
                react_1.default.createElement("div", { className: "h-1 bg-black shadow-sm" }),
                react_1.default.createElement("div", { className: "max-h-[200px] flex flex-col overflow-auto gap-1 px-1" }, lot.bids.bids && lot.bids.bids.length > 0 ? (lot.bids.bids.map(function (bid) {
                    var _a;
                    return (react_1.default.createElement("div", { key: "lwb-".concat(bid.entity_id), className: "flex flex-row items-center gap-1 border-b border-black py-1" },
                        react_1.default.createElement("div", { className: "flex-1 flex gap-2 items-center" },
                            react_1.default.createElement("div", { className: "text-md" }, doshx_controls_web_1.Utilities.formatMoney({
                                value: bid.amount,
                                decimalPlaces: 2,
                            })),
                            react_1.default.createElement("span", { className: "text-xs text-black" }, "-"),
                            react_1.default.createElement("div", { className: "text-xs text-blue-500" },
                                "#",
                                bid.bidder.paddle_number),
                            ((_a = bid.user) === null || _a === void 0 ? void 0 : _a.entity_id) === (user === null || user === void 0 ? void 0 : user.entity_id) ? ( //
                            react_1.default.createElement("div", { className: "text-[10px] text-sm bg-cyan-500 px-1 py-[2px] rounded-md text-white" }, "YOU")) : null),
                        react_1.default.createElement("div", { className: "flex" },
                            react_1.default.createElement("div", { className: "text-[10px]" }, doshx_controls_web_1.Utilities.formatDateTime(bid.createdAt)))));
                })) : (react_1.default.createElement("div", { className: "py-2 px-1" }, "No one has placed a bid here yet. Be the first!")))));
        })) : (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement("div", { className: "text-md font-bold" }, "No lots found mathing the crieteria.")))))));
};
exports.LotsWithBids = LotsWithBids;
