"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gallery = void 0;
var react_1 = __importStar(require("react"));
var Gallery = function (props) {
    var images = props.images, slide = props.slide;
    var duration = (slide || { duration: 3000 }).duration;
    var timer = (0, react_1.useRef)(null);
    var _a = (0, react_1.useState)(false), paused = _a[0], setPaused = _a[1];
    var _b = (0, react_1.useState)(0), selectedIndex = _b[0], setSelectedIndex = _b[1];
    (0, react_1.useEffect)(function () {
        if (!paused) {
            timer.current = setInterval(function () {
                // Change the selected index, & loop back to 0 if it reaches the end.
                setSelectedIndex(function (prev) { return (prev + 1) % images.length; });
            }, duration);
        }
        else {
            clearInterval(timer.current);
        }
        return function () {
            clearInterval(timer.current);
        };
    }, [paused]);
    return (react_1.default.createElement("div", { className: "h-80 aspect-square flex flex-col gap-1" },
        react_1.default.createElement("div", { className: "h-80 w-full overflow-clip rounded-lg shadow-md relative", onMouseEnter: function () {
                setPaused(true);
            }, onMouseLeave: function () {
                setPaused(false);
            }, onTouchStart: function () {
                setPaused(true);
            }, onTouchEnd: function () {
                setPaused(false);
            } }, images === null || images === void 0 ? void 0 : images.map(function (image, index) {
            return (react_1.default.createElement("img", { key: "gallery-image-".concat(index), src: image.src, alt: image.alt, className: "\n                w-full h-full object-cover absolute transition-all \n                duration-500\n                ".concat(selectedIndex === index ? "z-10" : "z-0", "\n                ").concat(selectedIndex === index ? "opacity-100" : "opacity-0", "\n              ") }));
        })),
        react_1.default.createElement("div", { className: "flex gap-1 flex-wrap justify-center", onMouseEnter: function () {
                setPaused(true);
            }, onMouseLeave: function () {
                setPaused(false);
            }, onTouchStart: function () {
                setPaused(true);
            }, onTouchEnd: function () {
                setPaused(false);
            } }, images &&
            images.length > 1 &&
            images.map(function (x, index) {
                return (react_1.default.createElement("div", { key: "gallery-thumb-".concat(index), className: "h-4 bg-blue-600 hover:bg-green-600 hover:w-6 duration-500\n                ".concat(selectedIndex === index ? "w-6" : "w-4", "\n                transition-all rounded-full shadow-md cursor-pointer\n              "), onClick: function () {
                        setSelectedIndex(index);
                    } }));
            }))));
};
exports.Gallery = Gallery;
