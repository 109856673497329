"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManualLiveAuctionComponent = void 0;
var react_1 = __importStar(require("react"));
var auction_details_1 = require("../../../../components/auction_details");
var video_feed_1 = require("./video_feed");
var previous_current_next_1 = require("../../../../controls/previous_current_next");
var react_redux_1 = require("react-redux");
var auction_1 = require("../../../../reducers/slices/auction");
var lot_1 = require("../../../../reducers/slices/lot");
var bidder_status_1 = require("../../../../components/bidder_status");
var lots_with_bids_1 = require("../../../../components/lots_with_bids");
var tabs_1 = require("../../../../controls/tabs");
var current_bidding_1 = require("./current_bidding");
var socket_1 = require("../../../../socket");
var ManualLiveAuctionComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var previousCurrentNext = (0, react_redux_1.useSelector)(function (state) { return state.lotState; }).previousCurrentNext;
    var liveAuction = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }).liveAuction;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.bidderState; }), isBidderVerified = _a.isBidderVerified, isBidderRegistered = _a.isBidderRegistered;
    // const [currentLot, setCurrentLot] = useState<any>(null);
    (0, react_1.useEffect)(function () {
        socket_1.socket.on("auctionManualStop", function (data) {
            console.log("auctionManualStop", data);
            var entity_id = data.entity_id, status = data.status;
            if (entity_id === liveAuction.entity_id) {
                dispatch((0, auction_1.setIsAuctionCompleted)(true));
            }
        });
    }, []);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4 lg:items-center" },
                react_1.default.createElement("div", { className: "flex-1" },
                    react_1.default.createElement(auction_details_1.AuctionDetailsComponent, null)),
                liveAuction && (
                //
                react_1.default.createElement(video_feed_1.VideoFeedComponent, { auction: liveAuction }))),
            react_1.default.createElement(bidder_status_1.BidderStatusComponent, null),
            react_1.default.createElement(previous_current_next_1.PreviousCurrentNext, { currentLotChanged: function (lot) {
                    dispatch((0, lot_1.setCurrentLot)(lot));
                } }),
            react_1.default.createElement(tabs_1.Tabs, { options: [
                    {
                        name: "Current",
                        content: react_1.default.createElement(current_bidding_1.CurrentBidding, null),
                    },
                    {
                        name: "All Lots",
                        content: react_1.default.createElement(lots_with_bids_1.LotsWithBids, null),
                    },
                ] }))));
};
exports.ManualLiveAuctionComponent = ManualLiveAuctionComponent;
