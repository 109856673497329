"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var auctions_for_client_1 = require("../../../api/calls/auction/auctions_for_client");
var parseJwt_1 = require("../../../helpers/parseJwt");
var main_1 = require("../../../reducers/slices/main");
var container_1 = require("../../../controls/container");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../constants/routes");
var auction_1 = require("../../../reducers/slices/auction");
var bidder_status_1 = require("../../../components/bidder_status");
var auction_status_tag_1 = require("../../../components/tags/auction_status_tag");
var AuctionsPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    // const { user, isLoading } = useSelector<any, IUserSliceState>((state: any) => state.userState);
    var _b = (0, react_1.useState)([]), auctions = _b[0], setAuctions = _b[1];
    var _c = (0, react_1.useState)(false), isLoading = _c[0], setIsLoading = _c[1];
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    (0, react_1.useEffect)(function () {
        setIsLoading(true);
        (0, auctions_for_client_1.auctions_for_client)({
            entity_id: client_entity_id,
        }).then(function (response) {
            setIsLoading(false);
            if (response.success) {
                setAuctions(response.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    }, []);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(container_1.ContainerControl, { className: "flex flex-col gap-8", loading: isLoading },
            react_1.default.createElement(bidder_status_1.BidderStatusComponent, null),
            react_1.default.createElement("div", { className: "grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4" }, auctions.map(function (auction, index) {
                var _a;
                return (react_1.default.createElement("div", { key: "auction-".concat(index), className: "flex flex-col bg-white rounded-lg shadow-lg hover:scale-105 transition-all cursor-pointer", onClick: function () {
                        switch (auction.status) {
                            case "Complete":
                                navigate("".concat(routes_1.AUCTION_COMPLETE_ROUTE, "/").concat(auction.entity_id));
                                return;
                            case "In-Progress":
                                navigate("".concat(routes_1.AUCTION_LIVE_ROUTE, "/").concat(auction.entity_id));
                                return;
                            default:
                                dispatch((0, auction_1.setAuction)(null)); //Reset the previous loaded auction.
                                navigate("".concat(routes_1.AUCTION_DETAILS_ROUTE, "/").concat(auction.entity_id));
                                return;
                        }
                    } },
                    react_1.default.createElement("div", { className: "aspect-square rounded-t-lg overflow-clip flex-none" },
                        react_1.default.createElement("img", { src: auction.images ? (_a = auction.images[0]) === null || _a === void 0 ? void 0 : _a.uploaded_file_url : "", className: "object-cover w-full h-full" })),
                    react_1.default.createElement("div", { className: "p-4 flex-1" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: auction.title, bold: true, size: "large", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: auction.description, size: "medium", textColor: "text-black", maxLines: "three" })),
                    react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                    react_1.default.createElement("div", { className: "p-4 pt-0 flex justify-between items-center" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatDateTime(auction.date_from, "YYYY-MM-DD"), size: "small", textColor: "text-black" }),
                        react_1.default.createElement(auction_status_tag_1.AuctionStatusTag, { auctionStatus: auction.status, size: "small" }))));
            })))));
};
exports.AuctionsPage = AuctionsPage;
