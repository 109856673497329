"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TermsAndConditionsPage = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../reducers/slices/main");
var get_configuration_1 = require("../api/calls/configuration/get_configuration");
var parseJwt_1 = require("../helpers/parseJwt");
var markdown_formatter_1 = require("../components/markdown_formatter");
var TermsAndConditionsPage = function (props) {
    var _a;
    var _b = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client_entity_id = (_a = (0, parseJwt_1.parseJwt)(process.env.CLIENT_TOKEN)) === null || _a === void 0 ? void 0 : _a.entity_id;
    var _c = (0, react_1.useState)("Loading Terms and Conditions..."), termsData = _c[0], setTermsData = _c[1];
    (0, react_1.useEffect)(function () {
        (0, get_configuration_1.get_configuration)({
            client_entity_id: client_entity_id,
        }).then(function (response) {
            if (response) {
                setTermsData(response.content.terms_contitions_md_text);
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Oops!",
                    messages: ["Sorry! We could not retrieve the privacy policy at the moment! Please try again later."],
                }));
            }
        });
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-row justify-center" },
        react_1.default.createElement("div", { className: "container" },
            react_1.default.createElement(markdown_formatter_1.MarkdownRenderer, { markdown: termsData }))));
};
exports.TermsAndConditionsPage = TermsAndConditionsPage;
